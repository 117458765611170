import {createReducer, on} from '@ngrx/store';
import * as UserActions from './user.actions';

export const accountInitialState = {
    account: '',
    availableAccounts: [],
    dispatcherEntityId: undefined,
    userProfileId: undefined,
    companyId: undefined
  };

export const reducer = createReducer(
    accountInitialState,
    on(UserActions.setAccount, (state, action) => ({
        ...state,
        account: action.payload
    })),
    on(UserActions.setAvailableAccounts, (state, action) => ({
        ...state,
        availableAccounts: action.payload
    })),
    on(UserActions.setDispatcherEntityId, (state, action) => ({
        ...state,
        dispatcherEntityId: action.payload
    })),
    on(UserActions.setUserProfileEntityId, (state, action) => ({
        ...state,
        userProfileId: action.payload
    })),
    on(UserActions.setCompanyId, (state, action) => ({
        ...state,
        companyId: action.payload
    }))
);
