import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppService } from './app.service';

@Component({
    selector: 'app-root',
    template: `
            <zui-progress-spinner
                *ngIf="isLoading$ | async"
                class="app-root-progress-spinner"
                color="accent"
                diameter=256
            ></zui-progress-spinner>
            <router-outlet></router-outlet>
        `,
})
export class AppRootComponent implements OnInit {
    public isLoading$!: Observable<boolean>;
    constructor(private appService: AppService) {
        // Adding 100 ms delay to avoid flickering effect
        this.isLoading$ = this.appService.isAppDataLoading$.pipe(delay(100));
    }

    ngOnInit() {

    }
}
