import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import { environment } from '@env/environment';
import { PermissionsService } from '@zonar-ui/auth';
import { Company } from '@zonar-ui/auth/lib/models/core-company-api.model';
import { User, UserProfile } from '@zonar-ui/auth/lib/models/core-user-api.model';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';
import { AppService } from './app.service';
import {DataDogService} from '@app/modules/services/datadog.service';

export interface AppRouteData {
    activeAccount: string;
    userProfiles?: UserProfile[];
    user?: User;
    selectedCompany?: Company;
}

@Injectable({
    providedIn: 'root'
})
export class AppResolver implements Resolve<Observable<AppRouteData>> {
    re = new RegExp(environment.swiftAccRegexCheck);

    constructor(
        private appService: AppService,
        private permissionsService: PermissionsService,
        private router: Router,
        private datadogService: DataDogService,
        private activatedRoute: ActivatedRoute) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppRouteData> {
        let activeAccount = route.queryParamMap.get('activeAccount');
        this.appService.isAppDataLoading$.next(true);

        //regex check here based on the environment - qaswi...., devswi...., etc. to update activeAccount
        if(this.re.test(activeAccount)) {
            activeAccount = environment.swiftAccToChangeTo;
        }

        if (activeAccount) {
            return this.permissionsService.userProfiles$
                .pipe(
                    take(1),
                    // Determines LoggedIn User is ZonarUser or not and returns {isZonarUser, profiles} to the next chain
                    switchMap((profiles) => this.permissionsService.isZonarUser().pipe(take(1), map(isZonarUser => (
                        { isZonarUser, profiles: this.getUserProfiles(profiles) })))),
                    // Input {isZonarUser, profiles}, returns {isZonarUser, profiles, dispatcher} to the next chain
                    switchMap(({ isZonarUser, profiles }) =>
                        iif(() => isZonarUser,
                            // If User is Zonar User get Zonar Dispatcher
                            this.appService.getZonarDispatcher(activeAccount).pipe(map(d => ({ isZonarUser, profiles, dispatcher: d }))),
                            // Otherwise get Dispatcher information from entity api (/dispatchers)
                            this.appService.getDispatcher(activeAccount, profiles[0].userId).pipe(take(1),
                                map(d => ({ isZonarUser, profiles, dispatcher: d })))

                        )),
                    switchMap(({ isZonarUser, profiles, dispatcher }) => forkJoin([of(profiles),
                        this.permissionsService.users$.pipe(take(1), map(users => users[0])),
                                this.appService.getCompanyBasedOnLegacyAccount(activeAccount), of(dispatcher)])),
                    // Input {isZonarUser, profiles, dispatcher}, returns {activeAccount, profiles, user, company, dispatcher}
                    switchMap(([userProfiles, user, company, dispatcher]) => forkJoin([of(activeAccount), of(userProfiles),
                    of(user), of(company), this.appService.getCompanyAccountsById(company[0].id), of(dispatcher)])),
                    // Map Input {activeAccount, profiles, user, company, dispatcher}, here it dispatches the active account and
                    // dispatcher entity (these are the ngrx store selectors, these selectors subscribed on different places of the
                    // application to read the active account and dispatcher id) and
                    // returns AppRouteData (It will be used on app component).
                    map(([activeAcc, userProfiles, user, company, accounts, dispatcher]) => {
                        this.appService.dispatchCompanyId(company[0].id);
                        this.appService.dispatchActiveAcct(activeAcc, accounts);
                        this.appService.dispatchDispatcherEntity(dispatcher);
                        this.permissionsService.setCurrentCompanyContext(company[0]);
                        return this.mapToAppRouteData(activeAcc, userProfiles, user, company[0]);
                    }),
                    catchError(err => this.handleError(err, activeAccount)),
                    finalize(() => this.appService.isAppDataLoading$.next(false))
                );
        }
        return this.permissionsService.userProfiles$
            .pipe(
                take(1),
                map(profiles => this.getUserProfiles(profiles)),
                switchMap(profiles => iif(
                    () => !!(profiles && profiles.length === 1 && profiles[0].companyId),
                    // True condition (When there is only one userprofile with companyId)
                    forkJoin([of(activeAccount), of(profiles),
                    this.permissionsService.users$.pipe(take(1), map(users => users[0])),
                    this.appService.setCompany(profiles[0]?.companyId, profiles[0]).pipe(take(1))]),
                    // False condition (When there are multiple profiles, use should select a company from company selector)
                    forkJoin([of(activeAccount), of(profiles),
                    this.permissionsService.users$.pipe(take(1), map(users => users[0])),
                    of(null)])
                )),
                map(([activeAcc, userProfiles, user, company]) => this.mapToAppRouteData(activeAcc, userProfiles, user, company)),
                catchError(err => this.handleError(err, activeAccount)),
                finalize(() => this.appService.isAppDataLoading$.next(false))
            );

    }

    private mapToAppRouteData(activeAccount: string, userProfiles: UserProfile[], user: User, selectedCompany: Company): AppRouteData {
        return {
            activeAccount,
            userProfiles,
            user,
            selectedCompany
        };
    }

    private getUserProfiles(profiles: UserProfile[]): UserProfile[] {
        let userProfile = profiles.filter((c: any) => c.applicationId === environment.auth.applicationId);
        if (!userProfile || userProfile.length === 0) {
            throw new Error('No userprofile found');
        }
        if(userProfile.length > 1) {
            const uniqueCompanyProfiles = [];
            userProfile.forEach(p => {
                if(uniqueCompanyProfiles.filter(u => u.companyId === p.companyId).length === 0) {
                    uniqueCompanyProfiles.push(p);
                }
            });
            userProfile = uniqueCompanyProfiles;
        }
        const prevSelection = this.appService.getPrevCompanySelection();
        if(prevSelection && Object.keys(prevSelection).length > 0) {
            const filteredProfiles = userProfile.filter((u: UserProfile) => (u.companyId === prevSelection.id &&
                u.userId === prevSelection.userId));
            if(filteredProfiles.length > 0) {
                userProfile = filteredProfiles;
            }
            // For Zonar Users CompanyId is empty on UserProfile
            if(userProfile && userProfile.length === 1 && userProfile[0].userId === prevSelection.userId
                && !userProfile[0].companyId) {
                userProfile[0].companyId = prevSelection.id;
            }
        }
        return userProfile;
    }

    private handleError(err: any, account: string): Observable<AppRouteData> {
        this.datadogService.addRumError(err);
        // If there is any error route to error page
        const pnfData = {
            title: 'UDL',
            error: err.name,
            errorMessage: err.message,
            status: err.status,
            url: environment.appUrl,
        };
        this.permissionsService.getUser().subscribe(x => {
            if (x === undefined || x === null) {
                const errorText = 'User returned from Entity /user api is empty. User is not defined in ELDx application';
                console.error(errorText);
                this.datadogService.log(errorText, {env: environment.prefix}, 'error');
            }
        });
        this.router.navigate(['404-page'], { queryParamsHandling: 'preserve', state: pnfData });
        return of({
            activeAccount: account
        });
    }

}
