<div ngClass.gt-sm="app-container-desktop" ngClass.lt-md="app-container-mobile" fxLayout="column" fxFill>
    <zui-header [openDialogOnPageLoad]="(activeAccount | async) === false"
    logoTitle="Hours of Service"
    [hasDefaultCompany]="hasDefaultCompany" [selectedCompany$]="selectedCompany$" (emitSelectedCompany)="setCompany($event)">
  </zui-header>
    <zui-hos-navigation [activeAccount]="account"></zui-hos-navigation>
    <div class="content-container" fxFlex>
    <zui-hos-banner [showBanner]="dispatcherIsNull" [bannerText]="text" [icon]="'warning'"></zui-hos-banner>
    <ng-container *ngIf="(activeAccount | async); else accountSpinner">
      <router-outlet></router-outlet>
    </ng-container>
    <ng-template #accountSpinner>
      <div class="account-spinner">
        <!--
          Styles for progress spinner applied from styles/styles.scss.
          Progress spinner pattern library theme has compile issue with the latest zonar-ui material library. 
        -->
        <zui-progress-spinner class="progress-spinner" color="accent" diameter=256></zui-progress-spinner>
      </div>
    </ng-template>
  </div>
    <div class="content-container">
        <zui-footer-component></zui-footer-component>
    </div>
</div>
