import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { PermissionsService } from '@zonar-ui/auth';
import { Company, Division } from '@zonar-ui/auth/lib/models/core-company-api.model';
import { UserProfile } from '@zonar-ui/auth/lib/models/core-user-api.model';
import {BehaviorSubject, iif, Observable, of, throwError} from 'rxjs';
import {catchError, map, retry, switchMap, take} from 'rxjs/operators';
import { State as UserState } from './modules/udl/state';
import {setAccount, setAvailableAccounts, setCompanyId, setDispatcherEntityId} from './modules/udl/state/user.actions';
import {AppRouteData} from '@app/app.resolver';


export const PREV_COMPANY_SELECTION_KEY = 'prevCompanySelection';

export interface PrevCompanySelection {
    id: string;
    name: string;
    userId: string;
    profileId: string;
    selectionTime: Date;
    legacyAccountCodeSelection?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AppService {

    public isAppDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private userStore: Store<UserState>,
        private permissionsService: PermissionsService) {
    }

    setCompany(companyId: string, userProfile: UserProfile): Observable<Company> {
        this.dispatchCompanyId(companyId);
        // return this.getLegacyAccountCode(companyId)
        return this.getCompanyAccountsById(companyId)
            .pipe(
                map(accounts => {
                    const prevSelection = this.getPrevCompanySelection();
                    let selectedAcct = accounts[0].value;
                    // If prev selection is present override with it.
                    if (prevSelection && prevSelection.id === companyId
                        && prevSelection.legacyAccountCodeSelection) {
                        selectedAcct = prevSelection.legacyAccountCodeSelection;
                    }
                    this.setPrevCompanySelection({
                        id: companyId,
                        name: '',
                        userId: userProfile.userId,
                        profileId: userProfile.id,
                        selectionTime: new Date(),
                        legacyAccountCodeSelection: selectedAcct
                    });
                    this.dispatchActiveAcct(selectedAcct, accounts);
                    return selectedAcct;
                }),
                switchMap((acc) => this.permissionsService.isZonarUser().pipe(take(1),map(isZonarUser => ({ acc, isZonarUser })))),
                switchMap(({ acc, isZonarUser }) =>
                    iif(() => isZonarUser,
                        this.getZonarDispatcher(acc),
                        this.getDispatcher(acc, userProfile.userId).pipe())),
                map(d => {
                    this.dispatchDispatcherEntity(d);
                }),
                switchMap(() => this.permissionsService.companyMap$.pipe(take(1), map(companies => {
                    if(companies && companies[companyId]) {
                        this.permissionsService.setCurrentCompanyContext(companies[companyId]);
                        return companies[companyId];
                    }
                    return null;
                }))),
                // Due to race condition, for Zonar users companyMap$ is empty sometimes on permission service.
                // So, here we are checking if company is null then making company call and setting it to the current context.
                switchMap((company) => iif(() => !!company, of(company),
                /* eslint no-underscore-dangle: 0 */
                this.permissionsService._getCompanyMap([companyId]).pipe(map((companies) => {
                    if (companies && companies[companyId]) {
                        this.permissionsService.setCurrentCompanyContext(companies[companyId]);
                        return companies[companyId];
                    }
                    return null;
                }))))
            );
    }

    setZonarUserDispatcherOnAccountChange(acc: string){
        this.permissionsService.isZonarUser()
            .subscribe((isZonarUser) => {
                if(isZonarUser) {
                    this.getZonarDispatcher(acc).subscribe(d => {
                        this.dispatchDispatcherEntity(d);
                    });
                } else {
                    //for normal users
                    this.setUserDispatcher(acc).subscribe((d) => {
                        this.dispatchDispatcherEntity(d);
                    });
                }
            });
    }

    setUserDispatcher(acc: string): Observable<any>{
        return this.permissionsService.getUser().pipe(
            switchMap((user) => this.getDispatcher(acc, user.id))
        );
    }

    dispatchActiveAcct(activeAcc: string, availableAccs: {value: string}[]): void {
        this.userStore.dispatch(setAccount({ payload: activeAcc }));
        this.userStore.dispatch(setAvailableAccounts({ payload: availableAccs }));
    }

    dispatchDispatcherEntity(d: { id: any}) {
        let dispatcher = d;
        if (Array.isArray(d)) {
            dispatcher = d[0];
        }
        this.userStore.dispatch(setDispatcherEntityId({ payload: dispatcher?.id?.toString() }));
    }

    dispatchCompanyId(id: string) {
        this.userStore.dispatch(setCompanyId({payload: id}));
    }

    getLegacyAccountCode(companyId): Observable<string[]> {
        return this.http.get<string[]>(`${environment.urls.externalApi}/companies/${companyId}/legacy-account-codes`);
    }

    getCompanyAccountsById(companyId: string): Observable<{value: string}[]> {
        return this.getCompanyLegacyTypeDivisions(companyId)
            .pipe(
                map(divisions => [...new Set((divisions || []).map(div => div.legacyAccountCode))]),
                map(accounts => accounts.map(acc => ({ value: acc }))));
    }

    getCompanyLegacyTypeDivisions(companyId: string): Observable<Division[]> {
        return this.http.get<Division[]>(`${environment.urls.externalApi}` +
        `/companies/${companyId}/divisions?type=LEGACY&sort=legacyAccountCode:asc`);
    }

    getCompanyBasedOnLegacyAccount(accountCode: string): Observable<any[]> {
        return this.http.get<string[]>(`${environment.urls.externalApi}/companies`, {params: {legacyAccountCode: accountCode}});
    }

    getDispatcher(acc, userId): Observable<any[]> {
        return this.permissionsService.getUser().pipe(
            switchMap((user) => this.getDispatcherByEmail(acc, user.email))
        );

        // todo: commenting out entity api dispatchers call until we need to switch to it
        // const url = `${environment.urls.externalApi}/dispatchers`;
        // // This is due to a bug on zonar owner id http interceptor
        // // Adding retry(1) sometimes this api is throwing 401 error due to zonar owner id undefined, it works after a retry.
        // // if no dispatcher from the entity api, find the legacy id via user email
        // return this.http.get<any[]>(url, { params: { userId } }).pipe(
        //     retry(5),
        //     switchMap((dispatchers) =>
        //         iif(
        //             () => dispatchers.length > 0,
        //             of(dispatchers),
        //             this.permissionsService.getUser().pipe(
        //                 switchMap((user) => this.getDispatcherByEmail(acc, user.email))
        //             )
        //         )
        // ));
    }

    getZonarDispatcher(accountCode): Observable<any> {
        const url = `${environment.urls.udlApi}/zonar-dispatcher/`;
        return this.http.get(url, { params: { accountCode } })
            .pipe(
            catchError((err) => this.zonarDispatcherError(err, accountCode))
            );
    }

    getDispatcherByEmail(accountCode: string, email: string): Observable<any> {
        const url = `${environment.urls.legacyEntityApi}/api/v1/dispatchers`;
        return this.http.get(url, {params: { account_code: accountCode, email}});
    }

    setPrevCompanySelection(company: PrevCompanySelection): void {
        localStorage.setItem(PREV_COMPANY_SELECTION_KEY, JSON.stringify(company));
    }

    updateAccountSelection(account: string): void {
        const prevSelection = this.getPrevCompanySelection();
        if(prevSelection) {
            prevSelection.legacyAccountCodeSelection = account;
            this.setPrevCompanySelection(prevSelection);
        }
    }

    getPrevCompanySelection(): PrevCompanySelection {
        let company: PrevCompanySelection = null;
        const prevSelection = localStorage.getItem(PREV_COMPANY_SELECTION_KEY);
        if (prevSelection) {
            try {
                company = JSON.parse(prevSelection) as PrevCompanySelection;
            } catch (error) {
                console.log(error);
            }
        }
        return company;
    }

    clearPrevCompanySelection(): void {
        localStorage.removeItem(PREV_COMPANY_SELECTION_KEY);
    }

    private zonarDispatcherError(err: any, account: string): Observable<AppRouteData> {
        // silently swallows error on zonar dispatcher
        console.log(err);
        if (err.status && err.status !== 404) {
            return throwError(() => new HttpErrorResponse(err));
        } else {
            console.log('Zonar dispatcher id is not set');
            return of({
                activeAccount: account
            });
        }
    }

}
